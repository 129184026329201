import {createStore} from 'vuex'
import {objectData} from "@/lib/interface";

export default createStore({
    state: {
        resType: false,
        resFunc: null,
        fileType: '',
        selectTitle: '选择文件',
    },
    getters: {},
    mutations: {
        openRes(state: any, type: Boolean) {
            state.resType = type;
        },
        doOpenRes(state: any, config: objectData) {
            if (config.hasOwnProperty('type')) {
                state.resType = config.type;
                if (config.hasOwnProperty('func') && typeof config.func === "function") {
                    state.resFunc = config.func;
                } else {
                    state.resFunc = null;
                }
                if (config.hasOwnProperty('fileType')) {
                    state.fileType = config.fileType;
                } else {
                    state.fileType = '';
                }
                if (config.hasOwnProperty('selectTitle')) {
                    state.selectTitle = config.selectTitle;
                } else {
                    state.selectTitle = '选择文件';
                }
            }
        },
    },
    actions: {},
    modules: {}
})
