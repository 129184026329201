import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {ElLoading} from 'element-plus';
import {checkLogin} from "@/lib/access";

let loadingInstance: any;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/components/layout/DefaultLayout.vue'),
        children: [
            {
                path: '/',
                component: () => import('@/components/layout/AdminLayout.vue'),
                children: [
                    {
                        path: '/',
                        name: 'index',
                        component: () => import('@/views/IndexView.vue'),
                    },
                    {
                        path: '/indexBannerImage',
                        name: 'indexBannerImage',
                        component: () => import('@/views/IndexBannerImage.vue'),
                    },
                    {
                        path: '/addBannerImage',
                        name: 'addBannerImage',
                        component: () => import('@/views/AddBannerImage.vue'),
                    },
                    {
                        path: '/indexBannerVideo',
                        name: 'indexBannerVideo',
                        component: () => import('@/views/IndexBannerVideo.vue'),
                    },
                    {
                        path: '/dhSet',
                        name: 'dhSet',
                        component: () => import('@/views/DhSetView.vue'),
                    },
                    {
                        path: '/addDh',
                        name: 'addDh',
                        component: () => import('@/views/AddDh.vue'),
                    },
                    {
                        path: '/kindSet',
                        name: 'kindSet',
                        component: () => import('@/views/KindSetView.vue'),
                    },
                    {
                        path: '/addKind',
                        name: 'addKind',
                        component: () => import('@/views/AddKind.vue'),
                    },
                    {
                        path: '/articleList',
                        name: 'articleList',
                        component: () => import('@/views/ArticleListView.vue'),
                    },
                    {
                        path: '/messageList',
                        name: 'messageList',
                        component: () => import('@/views/MessageListView.vue'),
                    },
                    {
                        path: '/readMessage',
                        name: 'readMessage',
                        component: () => import('@/views/ReadMessage.vue'),
                    },
                    {
                        path: '/systemSet',
                        name: 'systemSet',
                        component: () => import('@/views/SystemSetView.vue'),
                    },
                    {
                        path: '/articleEdit',
                        name: 'articleEdit',
                        component: () => import('@/views/ArticleEditView.vue'),
                    },
                    {
                        path: '/webSet',
                        name: 'webSet',
                        component: () => import('@/views/WebSet.vue'),
                    },
                    {
                        path: '/webDetail',
                        name: 'webDetail',
                        component: () => import('@/views/WebDetail.vue'),
                    },
                    {
                        path: '/footerSetting',
                        name: 'footerSetting',
                        component: () => import('@/views/FooterSetting.vue'),
                    },
                    {
                        path: '/copyRight',
                        name: 'copyRight',
                        component: () => import('@/views/CopyRight.vue'),
                    }
                ]
            },
            {
                path: 'login',
                name: 'login',
                component: () => import('@/views/LoginView.vue')
            },
        ]
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (loadingInstance && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
    loadingInstance = ElLoading.service({
        fullscreen: true
    });
    if (to.name != 'login' && !checkLogin()) {
        next({name: 'login', query: {redirect: encodeURIComponent(from.fullPath)}});
    } else if (to.name == 'login' && checkLogin()) {
        next({name: 'index'});
    } else {
        next();
    }

})

router.afterEach((to, from) => {
    if (loadingInstance && loadingInstance.hasOwnProperty('close') && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
})

export default router
