import {objectData} from "@/lib/interface";

/**
 * 判断对象是否是数组
 * @param arr
 * @return boolean
 */
function isArray(arr: any): boolean {
    return Object.prototype.toString.call(arr) === '[object Array]';
}

/**
 * 深度拷贝
 * @param obj
 */
function deepClone(obj: any) {
    // 对常见的“非”值，直接返回原来值
    if ([null, undefined, NaN, false].includes(obj)) return obj;
    if (typeof obj !== "object") {
        //原始类型直接返回
        return obj;
    }
    if (isArray(obj)) {
        let o: Array<any> = [];
        for (let i of obj) {
            o.push(deepClone(i));
        }
        return o;
    } else {
        let o: objectData = {};
        for (let i in obj) {
            if (obj.hasOwnProperty(i)) {
                o[i] = deepClone(obj[i]);
            }
        }
        return o;
    }
}

/**
 * 数据存储
 * @param key 要读取的键名
 * @param val 不传或undefined为读取，null为删除，其他为写入
 */
function localData(key: string, val: any = undefined): any {
    key = 'impulx_admin_' + key;
    if (val === undefined) {
        //读取
        let re = localStorage.getItem(key);
        if (re) {
            let reObj: objectData;
            try {
                reObj = JSON.parse(re);
                if (!reObj.hasOwnProperty('data')) {
                    reObj = {
                        'data': null
                    }
                }
            } catch (e) {
                reObj = {
                    'data': null
                }
            }
            return reObj.data;
        } else {
            return null;
        }
    } else if (val === null) {
        //读取
        localStorage.removeItem(key);
    } else {
        //写入
        localStorage.setItem(key, JSON.stringify({
            'data': val
        }))
    }
    return true;
}

/**
 * 获取对象值
 * @param data 对象
 * @param key 键名，支持点号分割多个键，支持".*"获取全部子级
 * @param defaultData 不存在时的默认数据
 */
function getKeyVal(data: any, key: string | number, defaultData?: any): any {
    if (typeof data !== 'object' || [null, undefined, NaN, false, true].includes(data)) {
        return defaultData === undefined ? null : defaultData;
    }
    let keyStr = key.toString();
    let keyArray = keyStr.split('.');
    if (keyArray.length <= 1) {
        //只有一个key
        if (data.hasOwnProperty(key)) {
            return data[key];
        } else {
            return defaultData === undefined ? null : defaultData;
        }
    } else {
        let val = data;
        //key有多个
        for (let i of keyArray) {
            if (i.length < 1) {
                val = null;
                break;
            } else if (val.hasOwnProperty(i)) {
                val = val[i];
            } else if (i === '*') {
                break;
            } else {
                val = null;
                break;
            }
        }
        return val;
    }
}

/**
 * 写入对象数据
 * @param data 被写入的对象
 * @param key 写入的key
 * @param setData 写入的数据，如果是null，则是删除
 */
function setKeyVal(data: any, key: string | number | null, setData: any): objectData {
    if (typeof data !== 'object' || [null, undefined, NaN, false, true].includes(data)) {
        data = {};
    }
    if (key === null || key === '*' || key.toString().length < 1) {
        return deepClone(setData);
    }
    let keyStr = key.toString();
    let keyArray = keyStr.split('.');

    let doFunc = (keys: Array<string>, val: any, childData: objectData) => {
        let newKey: number | string | undefined = keys.shift();
        if (newKey === undefined) {
            return val;
        }
        if (isArray(childData)) {
            //数组
            newKey = parseInt(newKey);
            if (isNaN(newKey)) {
                //是数组，但是键非法，如果数组有内容，那就加在后面，如果没数据，就把数组变换成对象
                if (childData.length > 0) {
                    let newData = doFunc(keys, val, {});
                    if (newData !== null) {
                        childData.push(newData);
                    }
                } else {
                    let newData = doFunc(keys, val, {});
                    if (newData !== null) {
                        childData = {};
                        childData[newKey] = newData;
                    }
                }
            } else {
                if (childData.hasOwnProperty(newKey)) {
                    let newData = doFunc(keys, val, childData[newKey]);
                    if (newData === null) {
                        //删除
                        childData.splice(newKey, 1);
                    } else {
                        childData.splice(newKey, 1, newData);
                    }
                } else {
                    let newData = doFunc(keys, val, {});
                    if (newData !== null) {
                        childData.push(newData);
                    }
                }
            }
        } else {
            if (typeof childData !== "object") {
                childData = {};
            }
            if (childData.hasOwnProperty(newKey)) {
                let newData = doFunc(keys, val, childData[newKey]);
                if (newData === null) {
                    delete childData[newKey];
                } else {
                    childData[newKey] = newData;
                }
            } else {
                let newData = doFunc(keys, val, {});
                if (newData !== null) {
                    childData[newKey] = newData;
                }
            }
        }
        return childData;
    }

    return doFunc(keyArray, setData, data);
}

/**
 * 获取时间戳
 * @param type 是否获取到毫秒级别
 */
const time = function (type: boolean = false) {
    let t = Date.parse(new Date().toString());
    if (type) {
        t = (new Date()).getTime();
    } else {
        t = t / 1000;
    }
    return t;
}

/**
 * 判断是否是开发环境
 */
const isDev = function () {
    return process.env.NODE_ENV === 'development';
}

/**
 * 判断当前设备是否为移动端
 */
const isMobileDevice = function () {
    const ua = navigator.userAgent.toLowerCase();
    const t1 = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mac/i.test(ua);
    const t2 = ua.indexOf("windows") < 0 && ua.indexOf("iphone") < 0 && navigator.maxTouchPoints > 1;
    return t1 || t2;
}


export {
    getKeyVal, setKeyVal, isArray, deepClone, localData, time, isDev, isMobileDevice
}
